@import "../../styles/Variables.scss";
.request-section {
  .width-50 {
    width: 50%;
  }
  .width-25 {
    width: 25%;
  }
  .width-mid {
    width: 25%;
  }
  .request-content {
    display: flex;
    background: $primaryColor;
    padding: 30px;
    border-radius: 10px;
    text-align: center;

    h2 {
      font-size: 36px;
      color: #fff;
      text-align: initial;
      height: 100%;
      display: flex;
      align-items: center;
    }
    img {
      height: 80px;
      width: 100%;
      object-fit: contain;
    }

    .main-btn {
      background: #fff;
      color: $red;
      font-weight: 500;
      margin-top: 0;
    }

    .request-request {
      img {
        height: 20px;
        margin-bottom: 10px;
      }

      .shine {
        transform: rotate(180deg);
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 992px) {
  .request-section {
    .request-content {
      h2 {
        font-size: 26px;
      }
    }
  }
}

@media (max-width: 768px) {
  .request-section {
    .width-50 {
      width: 60%;
    }
    .width-25 {
      width: 40%;
      .btn-section {
        display: flex;
        justify-content: end;
      }
    }
    .width-mid {
      display: none;
    }
    .request-request {
      img {
        display: none;
      }
    }
    .arrow {
      display: none;
    }

    .main-btn {
      font-size: 14px;
      white-space: nowrap;
    }
  }
}

@media (max-width: 480px) {
  .main
    .cont
    .base
    .slick-slider
    .slick-list
    .slick-track
    .slick-current
    .first-profile {
    margin-right: 0;
  }
  .request-section {
    .width-50 {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .width-25 {
      width: 100%;
      .btn-section {
        justify-content: center;
      }
    }
    .request-content {
      display: block;

      img {
        display: none;
      }
      h2 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 25px;
      }

      .request-request {
        text-align: initial;
      }
    }
    .btn-section {
      justify-content: center;
      display: flex;
    }
  }
}

@media (max-width: 360px) {
  .request-section {
    .request-content {
      h2 {
        font-size: 20px;
      }
    }
  }
}
