@import "../../styles/Variables.scss";

.benefits-section {
  .main-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: 1px;
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
  }
  .content-text {
    font-style: normal;
    color: $titleColor;
    font-size: 30px;
    font-weight: 600;
  }

  .card-section {
    margin-bottom: 30px;
    min-height: 300px;
  }

  .bg-hover-maroon:hover {
    background-color: $pinky;
  }
  .bg-hover-orange:hover {
    background-color: $bluee;
  }
  .bg-hover-green:hover {
    background-color: $lig-green;
  }
  .bg-hover-purple:hover {
    background-color: $purplee;
  }

  .benefit-content {
    margin-top: 30px;

    .ben-icon {
      //   padding: 12px;
      padding: 18px;
      height: 75px;
      width: 75px;
      border-radius: 15px;
      margin: auto;

      i {
        font-size: 35px;
      }
    }
    .outer-img-card {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      .b-1 {
        // background: $lightprimaryColor;
        .icon-img {
          width: 71px;
        }
      }

      .b-2 {
        // background-color: $lightSecondaryColor;
        .icon-img {
          width: 71px;
        }
      }

      .b-3 {
        // background-color: $lightGreen;
        .icon-img {
          width: 71px;
        }
      }

      .b-4 {
        // background-color: $lightPurple;
        .icon-img {
          width: 71px;
        }
      }
    }
    .see-btn {
      border: none;
      color: $primaryColor;
      background-color: transparent;
      cursor: pointer;
      padding: 10px;
    }
    // .see-btn:hover {
    //   border-color: $primaryColor;
    //   color: white;
    //   border-radius: 20px;
    //   padding: 10px;
    // }
    .more-text {
      display: none;
    }
    .mb-unset {
      margin-bottom: unset;
    }
  }
}

.benefit-sub-content {
  .title-content {
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    color: $darkGrey;
  }

  .less {
    display: -webkit-inline-box;

    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.missionV-col {
  margin-bottom: 60px;
  box-shadow: 5px 10px #888888;
  border-radius: 10px;
  padding: 12px;
  min-height: 280px;
}
@media (max-width: 480px) {
  .benefits-section {
    .card-section {
      margin: 20px;
      padding: 20px;
      width: auto;
      min-height: unset;
      height: auto;
    }
  }
}
