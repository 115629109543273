.usercentric-section {
  .sub-title {
    font-weight: 500;
  }
}
.card-deco {
  padding: 38px 53px 34px 44px;
  width: 85%;
  margin: 0 auto;
}

@media (max-width: 992px) {
  .usercentric-section {
    .card-section {
      padding: 30px;
    }
  }
}

@media (max-width: 768px) {
  .usercentric-section {
    .card-section {
      padding: 20px;
    }
  }
}