@import "../../styles/Variables.scss";
.dev-environment-section {
  .slick-next:before,
  [dir="rtl"] .slick-prev:before {
    color: black;
  }

  .slick-next {
    right: -25px;
  }

  .content-space {
    margin-bottom: 40px;
  }
  .slick-prev {
    left: -25px;
  }
  .slick-next:before,
  .slick-prev:before {
    color: black;
  }
  .height-66 {
    height: 66px;
  }
  .box {
    &:hover {
      .img-color {
        filter: grayscale(0);
        transition: 0.3s all;
      }
    }
  }
  .img-color {
    filter: grayscale(1);
    transition: 0.3s all;
  }
  .sub-title {
    font-size: 20px;
  }
  .resize-img {
    width: 150px !important;
  }
  .django-bg {
    background-color: green;
  }

  .slide-box {
    img {
      width: 100%;
    }
  }

  img {
    height: 90px;
    object-fit: contain;
    margin-bottom: 10px;
    margin: auto;
  }
  .lightpink-bg {
    box-shadow: 2px 2px 5px 0px rgba(245, 84, 152, 0.25);
  }
  .bg-lightpink {
    &:hover {
      background-color: $pinky;
    }
  }
}

@media (max-width: 480px) {
  .dev-environment-section {
    padding: 0 10px;
    .slick-slider {
      padding: 0 30px;
    }
    .slick-prev {
      left: -3px;
    }

    .slick-next {
      right: -3px;
    }
  }
}

@media (max-width: 360px) {
  .dev-environment-section {
    padding: 0 5px;

    .slide-box_ {
      img {
        width: 60%;
      }
    }
    .slick-slider {
      width: 100%;
      padding: 15px;
    }
    .slick-next {
      right: -12px;
    }

    .slick-prev {
      left: -12px;
    }
  }
}
