@import "../../styles/Variables.scss";
.sub-banner-content {
  .white {
    padding: 10px 20px;
    background-color: white !important;
    color: $red;
    margin: 20px 38px;
    box-shadow: 0px 10px 20px rgba(250, 250, 250, 0.15),
      0px 2px 6px rgba(190, 187, 187, 0.04),
      0px 0px 1px rgba(231, 231, 231, 0.04);
    border-radius: 20px;
    border: none;
    font-weight: 500;
    background: none;
  }
  .mg-bottom-0 {
    margin-bottom: 0;
  }
  .mid-btn-section{
    display: flex;
    justify-content: center;
    .main-btn{
      margin-top: 0;
    }
  }
}
