.team-details {
  .main-title {
    margin-bottom: 0;
  }
  .slider-section {
    .export {
      margin-bottom: 30px;
    }
    .slick-prev,
    .slick-next {
      border: 2px solid black;
      border-radius: 20px;
      height: 30px;
      width: 30px;
      top: 36%;
      padding: 1px 2px 5px 3px;
    }
    .slick-prev {
      transform: rotate(180deg);
      margin-top: -15px;
    }

    .slick-prev:before {
      content: "\2794";
      color: black;
    }

    .slick-next:before,
    [dir="rtl"] .slick-prev:before {
      content: "\2794";

      color: black;
    }
  }
  img {
    background-color: rgba(245, 135, 110, 0.15);
  }
  .team-details-section {
    margin-top: 40px;
    position: relative;
    overflow: hidden;
    .social {
      display: flex;
      flex-direction: column;
      top: 0px;
      left: -50px;
      padding: 15px;
      transition: left ease-in 0.2s;
      position: absolute;
      .bg-color {
        font-size: 20px;
        line-height: 40px;
        color: white;
      }
    }
    &:hover {
      .social {
        width: 56px;
        background-color: rgba(153, 0, 0, 0.3);
        height: 100%;
        left: 1px;
      }
    }
  }

  .mt-40 {
    margin-top: 40px !important;
  }
  .operations {
    width: 298.5px !important;
    margin: auto;
  }
  .mg-0 {
    margin: 0px !important;
    margin-top: 40px;
  }
  .team-details-content {
    margin: 30px 15px;
    margin-bottom: 40px;

    .photo {
      height: 300px;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      object-position: top;
    }
    img {
      height: 300px;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      display: unset;
      object-position: top;
    }

    .team-info {
      padding: 15px;

      .main-title {
        font-size: 20px;
        text-transform: uppercase;
        line-height: 23px;
        margin-bottom: 5px;
        font-weight: 400;
      }

      .content {
        margin-bottom: 0;
      }

      img {
        height: 40px;
        width: 40px;
        margin: auto;
      }
    }
    .upper-title {
      font-size: 20px;
      text-transform: uppercase;
      line-height: 23px;
      margin-bottom: 5px;
      font-weight: 400;
    }
  }
  // .social{
  //   position: relative;
  //   .bg-color{
  //     position: absolute;
  //     background-color: rgba(153, 0, 0, 0.15);
  //     width: 50px;
  //     height: 50px;
  //     top: -318px;
  //     right: 244px;
  //   }
  // }
}

@media (max-width: 992) {
  .team-details_ {
    .slider-section {
      .slick-prev {
        left: -20px;
      }
    }
  }
}

@media (max-width: 480px) {
  .team-details {
    .content{
      text-align: center;
    }
    .team-details-content {
      img {
        width: 90%;
      }
    }

    .slider-section {
      .slick-next {
        right: 0px;
      }

      .slick-prev {
        left: 0px;
      }
    }
  }
}
