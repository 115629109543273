@media (max-width: 992px) {
  .apply-section{
    .main-btn{
      margin-top: 0;
    }

  }
}

@media (max-width: 480px) {
    .apply-section{
      padding: 0 10px;
    }
  }
  @media (max-width: 375px) {
    .apply-section{
      padding: 0 5px;
    }
  }