.workpage-section {
  .main-btn{
    white-space: nowrap;
  }
  .right-img-section{
    width: 100%;
  }
  .text-50 {
    width: 50%;
  }
  .right-img{
    float: right;
  }
  .img-50 {
    width: 50%;
  }
  .img-right {
    position: absolute;
    right: 0;
  }
  .text-left {
    position: absolute;
    left: 0;
  }
  .mgb-4 {
    margin-bottom: 70px;
    position: relative;
    width: 100%;
  }
  .float-right {
    float: right;
  }
  .float-left-side {
    float: left;
  }
  .img-area {
    height: 380px;
    width: auto;
    object-fit: cover;
    border-radius: 20px;
  }

  .webapp-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .main-btn {
      width: 130px;
    }
  }
  @media (max-width: 992px) {
    .main-btn {
      white-space: nowrap;
    }
    .img-area {
      height: auto;
      width: 380px;
    }
  }
  @media (max-width: 768px) {
    .main-btn {
      white-space: nowrap;
    }
    .right-img-section{
      width: 100%;
    }
    .img-area {
      height: auto;
      width: 100%;
    }
    .float-left-side {
      margin-bottom: 30px;
    }
    .right-img{
      width: 50%;
    }
  }
  @media (max-width: 480px) {
    .text-50 {
      width: 100%;
    }
    .right-img{
      width: 100%;
    }
    .img-50 {
      width: 100%;
    }
    .float-left-side {
      float: unset;
    }
    .img-right{
      position: unset;
    }
    .text-left{
      position: unset;
    }
    .right-img-section{
      margin-bottom: 30px;
    }
  }
}
