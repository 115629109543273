@import "../../styles/Variables.scss";
.sub-banner-section {
  background-color: $mainColor;
  padding: 40px 60px;
  min-height: 300px;

  h2,
  p {
    color: white;
  }
  .top-section {
    width: 100%;
    .top-text {
      width: 70%;
      margin: auto;
    }
    .content{
      text-align: center;
    }
  }
}
