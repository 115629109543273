@import "../../styles/Variables.scss";

.platform-section {
  img {
    width: 100%;
  }
  .d-flex {
    align-items: center;
    height: 100%;
    .platform-content {
      .head-section {
        width: 260px;
      }
      .main-title {
        text-align: initial;
        line-height: 30px;
      }
      .content {
        text-align: left;
      }
    }
  }
}

// @media (max-width: 768px) {
//   .platform-section {
//     .platform-content {
//       .main-title {
//         line-height: 30px !important;
//       }
//     }
//   }
// }

// @media (max-width: 480px) {
//   .platform-section {
//     .d-flex {
//       .platform-content {
//         .main-title {
//           font-size: 36px;
//           margin-top: 10px !important;

//           line-height: 46px;
//         }
//       }
//     }
//   }
// }

// @media (max-width: 375px) {
//   .platform-section {
//     .platform-content {
//       .main-title {
//         font-size: 28px !important;
//         line-height: 30px !important;
//       }
//     }
//   }
// }

// @media (max-width: 320px) {
//   .platform-section {
//     .platform-content {
//       .main-title {
//         font-size: 28px !important;
//         line-height: 32px !important;
//       }
//     }
//   }
// }
