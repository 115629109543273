@import "../../styles/Variables.scss";

.box {
  ::-webkit-scrollbar {
    width: 4px;
    background: $lightSecondaryColor;
  }

  ::-webkit-scrollbar-thumb {
    background: $lightSecondaryColor;
    border-radius: 4px;
  }

  .container {
    .page {
      .scroll-bar {
        padding: 20px;
        overflow: scroll;
        overflow-x: hidden;
        min-height: 70vh;
        max-height: 70vh;
        height: 70vh;
        position: relative;
        padding-top: 0px;
      }

      .red {
        color: $primaryColor;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        text-align: left;
      }

      .top-section {
        .line {
          display: flex;
          justify-content: space-between;
        }

        .link-dec {
          justify-content: space-between;
          display: flex;
        }

        .link {
          display: flex;
        }

        .small {
          font-size: 48px;
          text-align: initial;
          width: fit-content;

          background: linear-gradient(
            87.51deg,
            #0a0909e0 42.32%,
            #990000f7 47.82%,
            #990000 48.86%,
            #df9797f2 98.89%
          );
          margin-bottom: 30px;

          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .gray {
          color: $darkBlue;
          text-align: left;
          font-weight: 400;
          font-size: 16px;
        }
      }

      .cross {
        font-weight: 500;
        cursor: pointer;
      }

      .second-section {
        text-align: left;
      }

      .third-section {
        text-align: left;
      }
      .white-btn {
        font-size: 12px;
        padding: 10px 20px;
        border-radius: 20px;
        font-weight: 500;
        font-size: inherit;
        border: none;
        color: $primaryColor;
      }

      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .link-dec {
          color: $primaryColor;

          a:link {
            text-decoration: none;
            color: $primaryColor;
          }

          a {
            color: $primaryColor;
          }
        }
        .main-btn {
          margin-top: 0;
        }
      }
    }
  }
  .btn-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    right: 0px;
    bottom: 20px;
  }
  .sm-content {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.03em;
    color: #2c313a;
  }
}

@media only screen and (max-width: 480px) {
  .box {
    .container {
      .page {
        padding: 10px;

        .content {
          font-size: 12px;
          line-height: 14px;
          text-transform: uppercase;
        }

        .top-section {
          .small {
            font-size: 30px;
          }
        }

        .second-section {
          .sm-content {
            font-size: 11px;
            line-height: 20px;
            color: #2c313a;
          }

          .sm-title {
            font-size: 12px;
            line-height: 10px;
          }
        }

        .third-section {
          .sm-title {
            font-size: 12px;
            line-height: 10px;
          }
          .sm-content {
            font-size: 11px;
            line-height: 20px;
          }
        }

        .footer {
          .link-dec {
            font-size: 12px;
          }

          .btn {
            .main-btn {
              font-size: 12px;
              padding: 6px 12px;
              margin-top: 0;
            }
            .white-btn {
              font-size: 12px;
              padding: 6px 12px;
              color: $primaryColor;
            }
          }
        }
      }
    }
  }
}

.scrollbar {
  height: 100vh;
  overflow: scroll;
}
