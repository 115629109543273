.banner-rel {
  height: calc(100vh - 74px);
  overflow-x: hidden;
  .banner-section {
    background-color: #16101a;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;

    .banner-content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      .main-title {
        color: #fff;
        display: flex;
        flex-direction: column;
        line-height: 45px;
        font-size: 40px;
      }
      .content {
        text-align: center;
        color: #fff;
      }
    }

    .banner-icon {
      position: absolute;
      top: 80%;
      left: 50%;
      i {
        color: #fff;
      }
      padding: 5px;
      border: 2px solid #fff;
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
  }
}
@media (max-width: 480px) {
  .banner-rel {
    .banner-section {
      .banner-icon {
        top: 76%;
        left: 46%;
        height: 35px;
        width: 35px;
      }
    }
  }
}

.slick-dots {
  display: none !important;
}
