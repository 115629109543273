.dev-environmentAD-section {
  .card-section {
    box-shadow: 1px 1px 4px rgba(153, 0, 0, 0.25);
    margin: auto;
    width: 70%;
    padding: 10px;
    display: flex;
    height: 500px;
  }
  .mid-img {
    margin: auto;
  }

  .sub-title {
    font-size: 20px;
  }
  .j-content {
    display: flex;
    justify-content: space-between;
  }
  img {
    height: 40px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  .min-height {
    height: 165px;
  }
}

@media (max-width: 992px) {
  .dev-environmentAD-section {
    .card-section {
      padding: 30px;
      margin: 20px 0;
      width: 85%;
    }
  }
}

@media (max-width: 768px) {
  .dev-environmentAD-section {
    padding: 0 10px;
    .card-section {
      width: 95%;
      margin: 15px;
      .sub-title {
        font-size: 15px;
      }
    }
  }
}
@media (max-width: 480px) {
  .dev-environmentAD-section {
    padding: 0 10px;

    .card-section {
      padding: 20px;
      margin: 0;
      margin-bottom: 25px;
      .sub-title {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
@media (max-width: 392px) {
  .dev-environmentAD-section {
    padding: 0 10px;

    .j-content {
      display: unset;
    }
    .card-section {
      padding: 20px;
      margin-bottom: 25px;
    }
  }
}
