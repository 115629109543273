@import "../../styles/Variables.scss";

.timeline-content {
  margin-top: 30px;

  .timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
    margin-top: 50px;
  }

  .sub-title {
    margin-bottom: 0;
  }

  .arrowhead {
    width: 0;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -15px;
    display: none;

    border-top: 0 solid transparent;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #eeeeee;
  }

  .timeline-content-area {
    margin: 0 30px;
  }
  .timeline > li {
    min-height: 100px;
    width: 100%;
    border-left: 2px solid #c4c4c4;
    position: relative;
    float: left;
    clear: left;
  }

  .timeline > li:nth-last-child(1) {
    border: none;
  }

  .timeline > li > .timeline-panel {
    width: 100%;
    float: left;
    position: relative;
    margin-top: -12px;
    margin-bottom: 30px;
  }

  .timeline > li > .timeline-panel:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;

    border-bottom: 15px solid transparent;
    content: " ";
  }

  .timeline > li > .timeline-panel:after {
    position: absolute;
    top: 27px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid blue;
    border-right: 0 solid pink;
    border-bottom: 14px solid transparent;
    content: " ";
    display: none;
  }

  .timeline-panel img {
    width: 100%;
    padding: 10px 10px 0 10px;
  }

  .timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  .timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }

  .timeline > li > .timeline-badge {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.2em;
    text-align: center;
    position: absolute;
    top: 16px;
    right: 5px;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    cursor: default;
  }

  .timeline > li.timeline-inverted > .timeline-badge {
    left: 5px;
  }

  .timeline > li > .timeline-badge:before {
    position: absolute;
    top: -20%;
    right: -11px;
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    margin-top: -10px;
    // background: #fff;
    border-radius: 10px;
    z-index: 10;
  }

  .timeline > li.timeline-inverted > .timeline-badge:before {
    left: -12px;
  }

  /*Content*/
  .timeline-title {
    margin-top: 0;
    padding-left: 20px;
    color: inherit;

    .title-content {
      margin: 0;
      margin-bottom: 5px;
      font-size: 18px;
    }

    .content {
      margin-bottom: 0;
    }
  }

  .timeline-body > p,
  .timeline-body > ul {
    padding: 0 20px 20px 20px;
    margin-bottom: 0;
  }

  .timeline-body > p + p {
    margin-top: 5px;
  }

  .timeline-footer {
    background-color: #f4f4f4;
    padding: 10px;
    text-align: center;
  }

  .timeline-footer > a {
    padding: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  .timeline-inverted {
    .timeline-badge:before {
      background-color: $bgmaroon;
    }
  }
}

@media (max-width: 992px) {
  .timeline-content {
    .timeline {
      margin-top: 15px;
    }
  }
}
@media (max-width: 768px) {
  .timeline-content {
    .timeline {
      margin-top: 5px;
    }
  }
}

@media (max-width: 480px) {
  .timeline-section {
    .timeline {
      max-width: 90%;
      margin-top: 0px;

    }

    .timeline > li {
      padding-left: 70px;
    }

    .timeline > li.timeline-inverted > .timeline-badge::before {
      left: 15px !important;
    }
  }

  .timeline::before {
    left: 25px !important;
    height: 75%;
  }

  .timeline > li {
    width: 100% !important;
    margin-bottom: 0 !important;
  }

  .timeline > li:nth-child(3) {
    margin-top: 0 !important;
  }
}
