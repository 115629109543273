@import "../../styles/Variables.scss";

.weserve-section {
  .lightpink-bg {
    box-shadow: 2px 2px 5px 0px rgba(245, 84, 152, 0.25);
  }

  .lightyellow-bg {
    box-shadow: 1px 1px 4px 0px rgba(253, 251, 146, 0.25);
  }

  .lightpurple-bg {
    box-shadow: 1px 1px 4px 0px rgba(168, 147, 255, 0.25);
  }

  .lightblue-bg {
    box-shadow: 1px 1px 4px 0px rgba(69, 162, 248, 0.25);
  }

  .side-card {
    .img-card {
      padding: 10px;
      width: 60px;
      border-radius: 20px;
      height: 60px;
      position: absolute;
      top: 30%;
      left: -33px;

      img {
        height: 40px;
        width: 40px;
      }
    }
  }
}
@media (max-width: 992px) {
  .weserve-section {
    .side-card{
      margin: 5px 10px 30px 30px;
    }
    .main-title {
      margin-bottom: 10px;
    }
  }
}
@media (max-width: 768px) {
  .weserve-section {
    .card-section {
      padding: 20px 20px 20px 60px;
    }
  }
}
@media (max-width: 480px) {
  .weserve-section {
    .side-card {
      margin: 5px 10px 30px 10px;

      .outer-img-card {
        text-align: center;
        display: flex;
        justify-content: center;
        .img-card {
          position: unset;
        }
      }
      .sub-title {
        text-align: center;
      }
      .content{
        text-align: center;
      }
    }
  }
}
