@import "../../styles/Variables.scss";


.tab-component {
    .react-tabs__tab {
        padding: 6px 30px !important;
    }


    // border-top: 5px solid $darkRed;

    .tab-title {

        display: flex;

        .tab-img-div {
            width: 60px;

            .tab-img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
        }

    }

}

.react-tabs__tab:focus {
    border-color: transparent;
    outline: none;
}




.react-tabs__tab--selected {

    background: transparent;
    border-color: transparent;
    // color: ;
    border-radius: 0;
    border-top: 3px solid $darkRed;
    color: #86af8a !important;
    // border-right: none;
    // border-top: 0;
    // border-left: 0;
}

.react-tabstab:focus {
    box-shadow: none;
    border-color: transparent;
}

.react-tabstab-list {
    border-bottom: 1px solid red;
}

.react-tabstab {
    padding: 10px 20px;

}

.tab-name {


    .tab-content {
        font-style: normal;
        font-weight: normal;
        font-size: 18px !important;
        color: #343D48 !important;
    }
}

.tab-main {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #343D48;
    opacity: 0.8;
}

.name {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    color: $mainColor;
}