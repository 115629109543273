@import "../../styles/Variables.scss";

button:disabled,
button[disabled] {
  border: 1px solid $gray !important;
  background: $gray !important;
  color: #fff !important;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 500;
}
.main-btn:disabled {
  opacity: 0.5;
}
.main-btn {
  padding: 10px 20px !important;
  border-radius: 20px !important;
}

.stepperthird-section {
  .card {
    .main-btn {
      color: white !important;
    }
    input {
      padding: 12px;
      border: 1px solid $borderLine;
      border-radius: 8px;
    }

    label {
      color: $gray !important;
    }
  }

  .MuiTextField-root {
    margin-top: 5px;
  }

  .material-textfield {
    margin-bottom: 5px;

    label {
      color: $gray !important;
    }

    input:focus {
      border-color: $innerBorder !important;
    }

    .msg-description {
      margin-top: -21px;
    }
  }

  .material-textfields {
    input:focus + label {
      color: $black;
      transform: translateY(-50%) scale(0.9);
    }

    .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
      font-size: 20px;
    }

    label {
      top: -5px;
      left: -14px;
      padding: 0 0.3rem;
    }
  }

  .card input:focus {
    border-color: $innerBorder;
  }

  .error {
    font-size: 13px;
    color: $errorColor;
    float: left;
    margin-bottom: 14px;
  }

  .mg-text {
    margin: 0 100px;
  }

  .MuiTextField-root {
    width: 100% !important;
  }

  .has-error {
    .MuiFormControl-root {
      .MuiInputLabel-root {
        color: red;
      }

      .Mui-focused {
        border-color: red;
      }

      .MuiOutlinedInput-root {
        .css-1rcvyyg {
          color: rgb(241, 255, 192);
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: red;
        }
      }
    }
  }

  .MuiBox-root {
    .MuiOutlinedInput-root {
      border-radius: 8px;
      margin-bottom: 10px;
      outline: 0;

      input {
        padding: 20px;
        height: 10px;
      }

      fieldset {
        top: 0 !important;
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }

    .MuiInputLabel-formControl {
      font-size: 15px;
      background-color: #fff;
      top: 1px;
      color: #a3a3a3;
    }

    .bi-exclamation-circle-fill {
      margin-right: 2px;
    }
  }
  .css-g3verc-MuiFormControl-root-MuiTextField-root {
    .Mui-focused {
      color: #0b55af;
    }
  }
}
