@import "../../styles/Variables.scss";

.position-section {
  .card-width {
    width: 220px;
  }

  .title-flex {
    display: flex;
    justify-content: space-between;
  }

  .nojob-img {
    height: 300px;
    object-fit: contain;
  }

  .cross {
    margin-right: 25px;
    margin-top: 10px;
    font-size: 18px;
    color: $black;
    font-weight: 600;
    cursor: pointer;
  }

  .card-section {
    box-shadow: 1px 1px 4px rgba(153, 0, 0, 0.25);
    border-radius: 5px;
    padding: 20px;

    .text-left {
      text-align: left !important;
      margin-bottom: 20px;
    }

    .mg-top {
      margin-top: 20px;
    }

    .sub-title {
      text-align: initial;
      line-height: 24px;
      color: #505050;
      min-height: 45px;
      display: -webkit-inline-box;
      max-width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 18px;
    }

    .d-flex {
      .content {
        margin-bottom: 5px;
      }

      i {
        color: $primaryColor;
      }

      .bi-geo-alt {
        margin-right: 8px;
      }
    }

    .prime-color {
      p {
        color: $primaryColor;
      }

      i {
        margin-left: 10px;
        font-size: 20px;
      }
    }
  }

  .content-item {
    margin-right: 31px;
  }
}

@media (max-width: 480px) {
  .position-section {
    padding: 0 10px;
  }
}
@media (max-width: 375px) {
  .position-section {
    padding: 0 5px;
  }
}
