@import "../../styles/Variables.scss";

.Benefits-section {
  .lightpink-bg {
    box-shadow: 2px 2px 5px 0px rgba(245, 84, 152, 0.25);
  }

  .lightgreen-bg {
    box-shadow: 1px 1px 4px 0px rgba(140, 255, 140, 0.25);
  }

  .lightpurple-bg {
    box-shadow: 1px 1px 4px 0px rgba(168, 147, 255, 0.25);
  }

  .lightblue-bg {
    box-shadow: 1px 1px 4px 0px rgba(69, 162, 248, 0.25);
  }
  .benefits-content {
    width: 70%;
    margin: 0 auto;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  .card-section {
    position: relative;
    margin: 30px;
    padding: 20px 20px 20px 80px;

    .img-card {
      padding: 10px;
      width: 60px;
      border-radius: 20px;
      height: 60px;
      position: absolute;
      top: 50px;
      left: -33px;
      .logo-img {
        height: 75px;
        width: 71px;
        position: absolute;
        left: -4px;
      }
    }

    .bg-green {
      background-color: $bgyellow;
    }

    .bg-pink {
      background-color: $bgpink;
    }

    .bg-purple {
      background-color: $bgpurple;
    }

    .bg-blue {
      background-color: $bgblue;
    }
  }
  .bg-lightpink:hover {
    background-color: $pinky;
  }
  .bg-lightblue:hover {
    background-color: $bluee;
  }
  .bg-lightgreen:hover {
    background-color: $lig-green;
  }
  .bg-lightpurple:hover {
    background-color: $purplee;
  }
  .outer-img-card {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .weserve-content {
    text-align: left;
  }
}
@media (max-width: 480px) {
  .Benefits-section {
    padding: 0 10px;
    .benefits-content {
      width: 100%;
    }
    .weserve-content {
      text-align: center;
    }

    .outer-img-card {
      .img-card {
        position: unset;
        .logo-img {
          position: unset;
        }
      }
    }
  }
}
@media (max-width: 375px) {
  .Benefits-section {
    padding: 0 5px;
  }
}
