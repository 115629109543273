.our-benifit-service {
  .main-title {
    font-size: 70px;
    line-height: 88px;
  }
  .sub-title {
    font-size: 50px;
    margin-top: 40px;
  }

  .front-img {
    object-fit: contain;
    width: 100%;
  }
}
.page-gap {
  margin-top: 40px;
}

@media (max-width: 768px) {
  .our-benifit-service {
    .main-title {
      font-size: 60px;
      line-height: 60px;
    }
    .sub-title {
      font-size: 45px;
      margin-top: 45px;
    }
  
  }
}

@media (max-width: 480px) {
  .our-benifit-service {
    padding: 0 10px;
    .main-title {
      font-size: 50px;
      line-height: 50px;
    }
    .sub-title {
      font-size: 40px;
      margin-top: 40px;
    }
  
  }
}
@media (max-width: 375px) {
  .our-benifit-service {
    padding: 0 5px;
  }
}
