.page-section {
  .head-title {
    text-transform: uppercase;
    background: linear-gradient(
      87.51deg,
      #0a0909e0 42.32%,
      #990000f7 47.82%,
      #990000 48.86%,
      #df9797f2 65.89%
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: {
      size: 80px;
    }
    font-weight: 700;
  }
}
