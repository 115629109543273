.custom-service-agile {
  .main-title {
    font-size: 70px;
    line-height: 88px;
  }
  .sub-title {
    font-size: 50px;
    margin-top: 20px;
  }

  .front-img {
    object-fit: contain;
    width: 100%;
  }
}
.page-gap {
  margin-top: 40px;
}

@media (max-width: 768px) {
  .custom-service-agile {
    .sub-title {
      font-size: 30px;
    }
    .main-title {
      font-size: 40px;
      line-height: 33px;
      margin-bottom: 19px;
    }
  }
}
@media (max-width: 480px) {
  .custom-service-agile{
    padding: 0 10px;
  }
}
@media (max-width: 375px) {
  .custom-service-agile{
    padding: 0 5px;
  }
}