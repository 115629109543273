@import "../../styles/Variables.scss";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.contact-section {
  .MuiTextField-root {
    width: 100% !important;
    margin-top: 5px;
  }

  .bi-exclamation-circle-fill {
    margin-right: 2px;
  }

  .card-section {
    box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.18),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    border: none;
  }

  .error {
    font-size: 13px;
    color: $errorColor;
    float: left;
    margin-bottom: 14px;
    text-align: left;
  }

  .contact-content {
    .form-control {
      margin-bottom: 0px;
    }

    .main-btn {
      margin: 15px 0;
      width: fit-content;
    }
  }

  .contact-details {
    padding: 55px 30px;
    text-align: initial;
    margin-bottom: 10px;

    .sub-details {
      display: flex;
      margin-bottom: 25px;

      i {
        font-size: 25px;
        width: 50px;
        height: 100%;
        margin: auto 0;
        color: $primaryColor;
      }

      .content {
        height: 60px;

        .sub-title {
          margin-bottom: 0;
        }
      }
    }
  }
}

.material-textfield {
  .label {
    color: $innerBorder !important;
  }

  .label:focus {
    color: black !important;
  }

  .msg-description {
    margin-top: -21px;
  }
}

.card input:focus {
  border-color: $innerBorder;
}

.card {
  border-radius: 5px;
  padding: 20px;

  .material-textfield {
    label {
      color: $gray !important;
    }

    input:focus {
      border-color: $innerBorder !important;
    }
  }

  .material-textfield {
    label {
      color: $gray !important;
    }

    input:focus + label {
      color: $black !important;
    }
  }

  .material-textfields {
    input:focus + label {
      color: $black;
      transform: translateY(-50%) scale(0.9);
    }

    .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
      font-size: 20px;
    }

    label {
      top: -5px;
      left: -14px;
      padding: 0 0.3rem;
    }
  }

  .material-textfields {
    input:focus {
      border-color: red !important;
    }

    .label {
      color: black !important;
    }
  }

  .text-area {
    width: 100%;
  }

  .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: $black;
    font-size: 20px;
  }

  .material-textfield {
    position: relative;
  }

  input {
    font-size: 1rem;
    outline: none;
    border: 1px solid $borderLine;
    border-radius: 8px;
    padding: 12px;
    color: $titleColor;
    transition: 0.1s ease-out;
  }

  input:focus {
    border-color: $primaryColor;
  }

  input:focus + label {
    color: $primaryColor;
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }

  input:not(:placeholder-shown) + label {
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }

  .submit-btn {
    background: linear-gradient(180deg, #b61e25 0%, #560d11 100%);
    border-radius: 5px;
    color: $white;
    border: 1px solid;
    padding: 8px;
    margin-bottom: 16px;
  }

  .medium-content {
    color: $greyLight;
  }
}

.card {
  border-radius: 5px;
  padding: 20px;
}

.text-area {
  width: 100%;
  border-radius: 8px;
  border: 1px solid $borderLine;
}

.material-textfield {
  margin-bottom: 5px;
  position: relative;
}

label {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: $inputBorder;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

textarea {
  resize: none;
}

.msg-label {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 20%;
  transform: translateY(-50%);
  background-color: white;
  color: $inputBorder;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

input {
  font-size: 1rem;
  outline: none;
  border: 0.5px solid $contentColor;
  border-radius: 5px;
  padding: 0.5rem 0.7rem;
  color: $titleColor;
  transition: 0.1s ease-out;
}

input:focus {
  border-color: $primaryColor;
}

input:focus + label {
  color: $primaryColor;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

textarea:focus + label {
  color: $primaryColor;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

input:not(:placeholder-shown) + label {
  // bottom: 0;s
  transform: translateY(-50%) scale(0.9);
}

textarea:not(:placeholder-shown) + label {
  // bottom: 0;s
  transform: translateY(-50%) scale(0.9);
}

.submit-btn {
  background: linear-gradient(180deg, #b61e25 0%, #560d11 100%);
  border-radius: 5px;
  color: $white;
  border: 1px solid;
  padding: 8px;
  margin-bottom: 16px;
}

.medium-content {
  color: $greyLight;
}
