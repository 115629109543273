@import "../../styles/Variables.scss";
.product-section {
  .mgt-30 {
    margin-top: 100px !important;
  }

  .main-title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .sub-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
  }
  img {
    height: 100% !important;
    width: 80%;
    object-fit: contain;
  }
  .product-contain {
    .content {
      font-size: 22px;
      font-weight: 500;
    }

    .d-flex {
      margin-left: 10px;
      margin-bottom: 12px;
      i {
        color: #29abe2;
        width: 50px;
        font-size: 35px;
        display: flex;
        align-items: center;
      }

      p {
        width: calc(100% - 50px);
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 28px;
        color: $contentColor;
      }
    }
  }
}
@media (max-width: 992px) {
  .product-section {
    .mgt-30 {
      margin-top: 10px !important;
    }
  }
}

@media (max-width: 480px) {
  .product-section {
    img {
      margin-bottom: 30px;
      width: 100%;
      padding: 0 40px;
    }
    .row:nth-child(odd) {
      flex-direction: column-reverse;
    }
    .product-contain {
      margin: 0 20px;

      .main-title {
        font-size: 30px;
      }

      .sub-title {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 480px) {
  .product-section {
    .product-contain {
      margin: 0 10px;
    }
  }
}
