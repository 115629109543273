.app-service {
  .main-title {
    font-size: 70px;
    line-height: 88px;
  }
  .sub-title {
    font-size: 50px;
  }
.img-contener{
  display: flex;
  justify-content: center;
  width: 100%;
}
  .front-img {
    object-fit: contain;
    width: 100%;
    align-items: center;
  }
}
.page-gap {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .app-service {
    .sub-title {
      font-size: 30px;
    }
    .main-title {
      font-size: 40px;
      line-height: 33px;
      margin-bottom: 19px;
    }
    .front-img {
      width: 100%;
    }
  }
}

@media (max-width: 992px) {
  .app-service {
    .card-section {
      margin: 30px 0;
      padding: 20px 20px 20px 50px;
    }
    .front-img {
      object-fit: contain;
      width: 70%;
    }
  }
}

@media (max-width: 480px) {
  .app-service {
    padding: 0 10px;
  }
}
@media (max-width: 375px) {
  .app-service {
    padding: 0 5px;
  }
}
