@import "../../styles/Variables.scss";

.offer-section {
  .container {
        .padding-bottom-0{
            padding-bottom: 0;
            margin-bottom: 0;
        }
        .padding-top-0{
            padding-top: 0;
        }


        .line-height {
            margin-bottom: 1px;
        }

        .contact-content {
            .card-section {
                padding: 61px 40px;
                width: 100%;
                margin: 0 auto;
                box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
                border-radius: 10px;
            }
        }
    }

    .contact-content {
      .card-section {
        padding: 61px 40px;
        width: 90%;
        margin: 0 auto;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
          0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
      }
    }
  }

  .file-uploads {
    border: 1px solid $borderLine;
    padding: 5px;
    border-radius: 8px;
  }

  .position-mg {
    margin-top: 30px;
  }
  .material-textfield {
    .label-manage {
      color: $gray !important;
      top: unset;
    }

    input:focus {
      border-color: $innerBorder !important;
    }
  }

  .mg-to {
    display: flex;
    justify-content: space-between;

    .pdf-upload {
      border: none;
    }

    .file-select {
      display: flex;
      text-align: center;
      align-items: center;

      .file-upload {
        margin-left: 19px;
      }
    }
  }

  .card-section {
    padding: 61px 40px;
    width: 100%;
    margin: 0 auto;
  }

  .section-mg {
    margin-top: 10px;
  }

  .line {
    display: flex;
    justify-content: space-between;
  }

  .main-btn {
    margin: unset;
    display: flex;
    // margin-top: 48px;
  }

  .bottom-btn {
    margin-right: 10px;
  }

  .content {
    margin-top: 10px;
  }

  .error {
    color: $primaryColor;
    border-radius: 8px;
    text-align: left;
    font-size: 13px;
    margin-bottom: 8px;
  }

  .bi-exclamation-circle-fill {
    margin-right: 2px;
  }

  .input-form {
    border: 1px solid #8e8e8e;
    line-height: 48px !important;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;

    input[type="file"]::file-selector-button {
      display: none;
      border: 2px solid #6c5ce7;
      padding: 0.2em 0.4em;
      border-radius: 0.2em;
      background-color: #a29bfe;
      transition: 1s;
    }

    input[type="file"]::file-selector-button:hover {
      background-color: #81ecec;
      border: 2px solid #00cec9;
    }

    label {
      margin-bottom: unset;

      .bi-upload {
        margin: 10px;
      }
    }

    .ml-10 {
      margin-left: 10px;
    }

    .mr-10 {
      margin-right: 10px;
    }
  }

  .css-13r4j3i .MuiTextField-root {
    margin-left: unset;
    margin-right: 10px;
  }

  .input-deco {
    display: flex;
  }

  .CV-label {
    text-align: left;
    color: $inputBorder;
  }

  .text-area {
    padding: 12px;
  }

  input:focus + label {
    color: $gray;
    transform: translateY(-50%) scale(0.9);
  }
