@import "../../styles/Variables.scss";

.navbar-section {
  .navbar {
    padding: 0;
  }
  .nav-btns {
    justify-content: center;
    display: flex;
    .main-btn {
      margin-top: 0;
    }
  }

  .nav-bg {
    position: relative;
    width: 100%;
    padding: 10px 0;
    z-index: 11;
    .main-btn {
      margin-top: 0;
    }
  }

  .navleft-auto {
    margin-left: auto;
  }

  .navbar-brand {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
  }

  .nav-item {
    margin-left: 20px;
    padding: 10px 15px;

    a {
      font-size: 16px;
      line-height: 19px;
      color: #fff !important;
    }
  }
  .line {
    display: flex;
    align-items: center;
    img {
      margin-right: 1px;
    }
  }

  img {
    margin-right: 10px;
  }
  .red-content {
    color: $red;
    font-weight: 500;
    color: $red;
  }

  .navbar-light .navbar-brand:focus,
  .navbar-light .navbar-brand:hover {
    color: white;
  }

  .show-content {
    padding: 20px;
    text-align: initial;
  }
  .drop-box {
    &:hover {
      background-color: $borderColor;

      .drop-icon {
        i {
          color: $red;
        }
      }

      .drop-content {
        color: $red;
      }
    }
  }
  a {
    text-decoration: none;
  }
  .drop-icon {
    font-size: 15px;
    padding: 0px 3px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    width: 200px;
    left: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .drop-content {
    font-size: 16px;
    margin-bottom: 0;
  }

  .div1:hover + .showdiv1 {
    display: block;
    background-color: white;
  }

  .dropdown:hover {
    .dropdown-content {
      display: block !important;

      ul {
        list-style-type: none;
        padding-left: 0;
        li {
          color: black !important;
          display: flex;
          padding: 15px 20px;
          text-align: initial;
        }
      }
    }
  }

  .dropdown-service:hover {
    .servicedrop-content {
      display: block !important;

      ul {
        list-style-type: none;
        padding-left: 0;
        li {
          color: black !important;
          display: flex;
          padding: 15px 20px;
          text-align: initial;
        }
      }
    }
  }

  .servicedrop-content {
    display: none;
    position: absolute;
    background-color: #fff;
    width: 200px;

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-service:hover {
    .servicedrop-content {
      display: block !important;

      ul {
        list-style-type: none;
        padding-left: 0;
        li {
          padding: 15px 20px;
          text-align: initial;
          display: flex;
        }
      }
    }
  }
}

.middlepage-section {
  .navbar-section {
    background-color: $blue;
    .navbar-brand {
      color: #fff;
    }

    .navbar-brand:hover {
      color: #fff;
    }
  }

  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: #fff !important;
      }
    }
  }
}

@media (max-width: 992px) {
  .navbar-section {
    .servicedrop-content {
      width: auto;
      white-space: nowrap;
    }
    .dropdown-content {
      width: auto;
      white-space: nowrap;
    }
  }
}
@media (max-width: 768px) {
  .navbar-section {
    .navbar-light {
      .navbar-toggler {
        background-color: white;
      }
    }
    
    .navbar-collapse {
      background: rgb(22, 16, 26);
      padding: 20px;
      text-align: left;
    }
    .nav-btns {
      display: unset;
    }
    
    .nav-item{
      margin: unset;
      padding-left: 10px;
    }
  }

  .middlepage-section {
    .navbar-light {
      .navbar-nav {
        .nav-link {
          color: #fff !important;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .navbar-toggler {
    padding: 6px 8px;
    font-size: 10px;
  }
  .servicedrop-content {
    width: auto;
    white-space: unset !important;
  }
}
