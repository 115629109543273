@import "../../styles/Variables.scss";

.main {
  .cont {
    width: 80%;
    margin: 0 auto;

    .main-box {
      margin: 50px;
      background-repeat: no-repeat !important;
      background: url(../../images/quote.png);
      background-position: 45px 73px;
      .main-title {
        margin-bottom: 43px;
      }
      .slick-list {
        .page {
          width: 90% !important;
          .content {
            font-size: 18px;
            color: $mainColor;
            text-align: center;
          }
        }
      }
    }
    .base {
      margin: 10px;
      .slick-slider {
        .slick-next:before,
        .slick-prev:before {
          display: none;
        }
        .slick-list {
          .slick-track {
            .slick-slide {
              .first-profile {
                border-top: 2px solid transparent;
                margin-right: 50px;
                padding-top: 25px;
                border-radius: 2px;
                max-width: 240px !important;
                .profile-content {
                  display: flex;
                  align-items: flex-start;
                  justify-content: center;

                  .images {
                    margin-right: 13px;
                    width: 50px;
                    height: 50px !important;
                    border-radius: 50%;
                  }
                  .sub-title {
                    margin-bottom: 0px;
                    text-align: left;
                  }
                  .content {
                    font-size: 15px;
                    margin-bottom: 0px;
                    text-align: left;
                  }
                }
              }
            }
            .slick-current {
              .first-profile {
                border-top: 2px solid red;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .main {
    .cont {
      .main-box {
        margin-top: 0 !important;
      }
      .page {
        text-align: center;
        .content {
          line-height: 30px;
        }
      }
      .sub-title {
        line-height: 10px;
      }
      .content {
        line-height: 20px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .main {
    .cont {
      width: 100%;
      .main-box {
        margin: 10px;
        text-align: center;
        .main-title {
          margin-bottom: 15px;
        }
        .page {
          text-align: center;
          .content {
            line-height: 28px;
          }
        }
      }
      .sub-title {
        line-height: 20px;
      }
    }
  }
}
@media (max-width: 480px) {
  .main {
    margin-top: 0 !important;
    padding: 0 10px;

    .cont {
      .main-box {
        margin: 0;
        .main-title {
          margin-bottom: 10px;
        }
        .page {
          text-align: center;

          .content {
            font-size: 16px !important;
            line-height: 22px !important;
          }
        }
        .first-profile {
          margin-right: unset;
        }
      }
      .sub-title {
        line-height: 10px;
      }
      .content {
        line-height: 16px;
      }
    }
  }
}
@media (max-width: 375px) {
  .main {
    padding: 0 5px;
    .page {
      text-align: center;
    }
  }
}
