.work-section {
  .slick-slide {
    height: auto; 
  }
  .top-section {
    justify-content: space-between;
    display: flex;
    padding: 0 10px;
    align-items: center;
  }
}

// @media (max-width: 992px) {
//   .work-section {
//     .slick-slide {
//       height: 450;
//     }
//   }
// }

@media (max-width: 768px) {
  .work-section {
    
    .secondary-btn {
      padding: 5px 11px;
      font-size: 14px;
    }
  }
}

@media (max-width: 365px) {
  .work-section {
    .main-title {
      font-size: 25px;
    }
    .top-section {
      display: unset;
    }
  }
}
