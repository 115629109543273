$mainColor: #0f2137;
$contentColor: #777d85;
$primaryColor: #990000;
$red: #a9301e;
$darkRed: #990000;
// $primaryColor: #29abe2;
$lightprimaryColor: #eaf7fc;
$secondaryColor: #e26029;
$lightSecondaryColor: #fcefea;
$lightGreen: #ecf4ef;
$green: #40975f;
$purple: #722ab5;
$lightPurple: #f1e9f7;
$borderColor: #d6dde6a6;
$black: #303030;
$white: #fff;
$accentColor: #101c3d;
$gray: gray;
$outlinewhite: #ffffff99;
$primaryBorder: #bfe6f64f;
$historybgblue: #eaf7fc;
$historybgpink: #fef2ed;
$labelColor: #a6a6a6;
$darkGrey: #2c313a;
$titleColor: #0f2137;
$grayColor: #505050;
$bgyellow: #ffcf4c;
$bgpink: #f06da1;
$bgpurple: #9c85ff;
$bgblue: #44a2f8;
$bgmaroon: #990000;
$bgskyblue: #3bdddd;
$bgorange: #fd9f7b;
$bggreen: #28dcb2;
$blue: #0f2137;
$pinky: #fcdfeb;
$bluee: #d7ebfd;
$yellow: #fffccc;
$purplee: #e7e2fe;
$lig-orange:#fdd5c5;
$maroon-lig:#ffbbbb;
$lig-green:#d0fcdf;
$inputBorder: #818181;
$greyLight:#505050;
$darkRed: #560D11;
$red:#B61E25;
$primaryColor: #a40606;
$borderLine:#E5E5E5;
$innerBorder:#0F2137;
$errorColor:#E90909;
$inputColor:#09101D;
$darkBlue:#343D48;
$contentBlack:#2C313A;