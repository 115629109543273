@import "../../styles/Variables.scss";

.footprint-section {
  .card-section {
    height: 200px;
    margin: 2px 20px;
    .main-title {
      font-size: 36px;
      margin-bottom: 0;
    }

    .center-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      .content {
        margin-bottom: 0;
      }
    }
  }
  .layer-bg-orange:hover {
    background-color: $lig-orange;
  }
  .layer-bg-pink:hover {
    background-color: $pinky;
  }
  .layer-bg-skyblue:hover {
    background-color: $bluee;
  }
  .layer-bg-maroon:hover {
    background-color: $maroon-lig;
  }

  .card-top {
    margin-top: 50px;
  }

  .footprints-content {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .d-flex {
    display: flex !important;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .footprint-section {
    .card-section {
      height: 150px;
      padding: 10px;
      margin: 5px 15px;
    }
    .card-top {
      margin-top: 30px;
    }
  }
  .platform-content {
    padding: 0px 20px;
  }
}

@media (max-width: 480px) {
  .footprint-section {
    .col-md-6 {
      margin-bottom: 0;
    }
    .card-section {
      height: 140px !important;
      padding: 20px !important;
      .main-title {
        font-size: 32px;
      }
    }

    .footprints-content {
      margin: 15px 20px 0 20px;
    }
  }
}

@media (max-width: 375px) {
  .footprint-section {
    .card-section {
      padding: 15px !important;
      margin-right: 30px;

      .main-title {
        margin-bottom: 5px;
      }
    }
  }
}

@media (max-width: 360px) {
  .footprint-section {
    .card-section {
      margin-right: 20px;

      .main-title {
        font-size: 28px;
      }

      .content {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 320px) {
  .footprint-section {
    .card-section {
      margin-right: 10px;
    }
  }
}
