@import "../../styles/Variables.scss";

.missionVision-section {
  .content-text {
    font-size: 30px;
    font-weight: 600;
    color: $black;
    font-style: normal;
  }
  .missionV-col {
    background-color: transparent;
  }
  .missionV-col:hover {
    background-color: $bluee;
  }

  .missionV-content {
    margin-top: 30px;

    .ben-icon {
      //   padding: 12px;
      padding: 18px;
      height: 75px;
      width: 75px;
      border-radius: 15px;
      margin: auto;

      i {
        font-size: 35px;
      }

      img {
        height: 40px;
      }
    }

    .b-1 {
      background: transparent;
    }

    .b-2 {
      background-color: transparent;
    }

    .b-3 {
      background-color: transparent;
    }

    .b-4 {
      background-color: $lightPurple;
    }
  }

  .missionV-col {
    margin-bottom: 60px;
    box-shadow: 1px 1px 4px rgba(68, 145, 188, 0.25);
    border-radius: 10px;
    padding: 12px;
    min-height: 280px;
  }
}

@media (max-width: 992px) {
  .missionVision-section {
    .missionV-col {
      height: 340px;
    }
  }
}

@media (max-width: 768px) {
  .missionVision-section {
    .content-text {
      font-size: 25px;
    }
  }
}
@media (max-width: 480px) {
  .missionVision-section {
    margin: 40px 20px 0px 20px !important;
    .missionV-col{
      height: auto;
      margin-bottom: 20px;
      min-height: unset;
    }
  }}
