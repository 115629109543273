@import "../../styles/Variables.scss";

.css-vnkopk-MuiStepLabel-iconContainer {
  border: 2px solid black;
  //   padding: 3px !important;
  border-radius: 50%;
  margin-right: 10px;
  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    width: 15px;
    margin-left: 4.5px;
    margin-right: -3px;
  }
}

.step-two {
  margin-top: 62px;
  ul {
    list-style-type: none;
  }

  li {
    display: inline-block;
  }

  input[type="checkbox"][id^="myCheckbox"] {
    display: none;
  }

  label {
    // border: 1px solid transparent;
    padding: 10px;
    display: block;
    position: relative;
    margin: 0px;
    cursor: pointer;
  }

  .images {
    width: 35px;
    height: 35px;
  }

  .checkbox-content {
    width: calc(100% - 35px);
  }

  label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: 2px;
    right: 2px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }

  label img {
    height: 100px;
    width: 100px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }

  :checked + label {
    border-color: #ddd;
  }

  :checked + label:before {
    content: "✓";
    background-color: $darkRed;
    transform: scale(1);
  }

  :checked + label img {
    transform: scale(0.9);
    /* box-shadow: 0 0 5px #333; */
    z-index: -1;
  }

  .card-section {
    // width: 68%;
    display: flex;
  }

  .stepper-content {
    margin: auto;
    width: 68%;
  }
}

@media (max-width: 480px) {
  .step-two {
    .card-section {
      padding: 30px 15px;
    }
    .checkbox-content{
      width: 100%;
    }
    
  }
}