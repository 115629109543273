@import "../styles/Variables.scss";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800;900&display=swap");

body {
  font-family: "Work Sans", sans-serif !important;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.section-gap {
  margin-top: 60px !important;
}

.mb-30 {
  margin-bottom: 40px;
}

.main-title {
  font-size: 30px;
  line-height: 40px;
  color: $mainColor;
  font-weight: 500;
}

.sub-title {
  font-size: 18px;
  line-height: 30px;
  color: #2c313a;
}

.text-init {
  text-align: initial;
}

.content {
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: left;
  color: $darkBlue;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.main-btn {
  padding: 10px 20px;
  background: linear-gradient(
    274.78deg,
    #990000 6.8%,
    #990000 6.8%,
    rgba(204, 128, 128, 0.5) 102.61%
  );
  white-space: nowrap;
  box-shadow: 0px 10px 20px rgba(153, 0, 0, 0.15),
    0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 20px;

  border: none;
  color: #fff;
  font-weight: 500;
}

.content-text {
  color: $bgmaroon;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 0;
}

.secondary-btn {
  background-color: #fff;
  color: $bgmaroon;
  padding: 10px 20px;
  border-color: $bgmaroon;
  border-radius: 70px;
  font-weight: 500;
}

.card-section {
  box-shadow: 1px 1px 2px;
  border-radius: 15px;
  padding: 30px;
}

.layer-bg-maroon {
  box-shadow: 1px 1px 4px rgba(153, 0, 0, 0.25);

  .img-card {
    background-color: $bgmaroon;
  }

  .title-color {
    color: $bgmaroon;
  }
}

.layer-bg-skyblue {
  box-shadow: 1px 1px 4px rgba(61, 221, 221, 0.25);

  .img-card {
    background-color: $bgskyblue;
  }

  .title-color {
    color: $bgskyblue;
  }
}

.layer-bg-orange {
  box-shadow: 1px 1px 4px rgba(255, 164, 130, 0.25);

  .img-card {
    background-color: $bgorange;
  }

  .title-color {
    color: $bgorange;
  }
}

.layer-bg-blue {
  box-shadow: 1px 1px 4px rgba(29, 161, 233, 0.25);

  .img-card {
    background-color: $bgblue;
  }

  .title-color {
    color: $bgblue;
  }
}

.layer-bg-pink {
  box-shadow: 2px 2px 5px rgba(245, 84, 152, 0.25);

  .img-card {
    background-color: $bgpink;
  }

  .title-color {
    color: $bgpink;
  }
}

.banner-icon {
  padding: 5px;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: 30px auto;

  i {
    color: #fff;
  }
}

.layer-bg-green {
  box-shadow: 2px 2px 5px#28DCB2;

  .img-card {
    background-color: $bggreen;
  }

  .title-color {
    color: $bggreen;
  }
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: $darkRed !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  display: block;
  -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: $grayColor !important;
}

//outline input field
.css-1x5jdmq {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.css-1x5jdmq:focus {
  outline: 0;
}

.css-igs3ac {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: red;
  border-width: 2px;
}

.css-1v4ccyo {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.head-title {
  background: linear-gradient(
    90.51deg,
    #18191f 25.32%,
    #990000 41.82%,
    #990000 63.86%,
    #ff4343 85.89%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 20px;

  font: {
    size: 48px;
  }

  font-weight: 700;
}
.mg-top-0 {
  margin-top: 0;
}
.mg-bottom-0 {
  margin-bottom: 0;
}

.bg-yellow {
  background-color: $bgyellow;
}

.bg-pink {
  background-color: $bgpink;
}

.bg-purple {
  background-color: $bgpurple;
}

.bg-blue {
  background-color: $bgblue;
}

.bg-pinky:hover {
  background-color: $pinky;
}
.bg-lightblue:hover {
  background-color: $bluee;
}
.bg-yelloww:hover {
  background-color: $yellow;
}
.bg-lightpurple:hover {
  background-color: $purplee;
}

.side-card {
  position: relative;
  margin: 30px;
  width: auto;
  padding: 20px 20px 20px 60px;
  border-radius: 15px;
}
.mid-card-section {
  position: relative;
  width: auto;
  padding: 20px;
  border-radius: 15px;
}

@media (max-width: 992px) {
  .side-card {
    padding: 20px 20px 20px 50px;
  }
  .section-gap {
    margin-top: 40px !important;
  }
  
}
@media (max-width: 768px) {
  .sub-title {
    font-size: 16px;
  }
  .main-title {
    line-height: 30px;
    font-size: 24px;
    margin-bottom: 0;
  }
  .content {
    font-size: 14px;
    line-height: 24px;}
}

@media (max-width: 480px) {
  .section-gap {
    margin-top: 20px !important;
  }
  .content {
    font-size: 14px;
  }
  .side-card {
    padding: 20px;
  }
  .sub-title {
    font-size: 16px;
  }
  .main-title {
    line-height: 30px;
    font-size: 24px;
    margin-bottom: 0;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .content-text {
    font-size: 12px;
    line-height: 35px;
  }

  .section-gap {
    margin-top: 30px !important;
  }

  .card-section {
    position: relative;
    margin: 15px;
    // padding: 19px 10px 10px 44px;
  }

  .mb-30 {
    margin-bottom: 10px;
  }
}
