.custom-service {
  .main-title {
    font-size: 70px;
    line-height: 88px;
  }
  .sub-title {
    font-size: 50px;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 240px;
  }
}

@media (max-width: 768px) {
  .custom-service {
    .sub-title {
      font-size: 30px;
    }
    .main-title {
      font-size: 40px;
      line-height: 33px;
      margin-bottom: 19px;
    }
  }
}

@media (max-width: 480px) {
  .custom-service {
    .custom-web-section {
      padding: 20px;
      padding-bottom: 0;
    }
  }
}
