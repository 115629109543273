.centricProcess-section {
  .mgb-30 {
    margin-bottom: 40px;
  }
  .centricProcess-content {
    text-align: initial;
    margin-bottom: 5px;

    i {
      font-size: 22px;
      display: flex;
      width: 60px;
    }
    h2 {
      line-height: 23px;
      margin-bottom: 5px;
    }

    // p {
    //   font-size: 16px;
    //   line-height: 28px;
    // }
  }
}

@media (max-width: 768px) {
  .centricProcess-section {
    .mgb-30 {
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 480px) {
  .centricProcess-section {
    padding: 0 10px;
    .mgb-30 {
      margin-bottom: 20px;
      .sub-title {
        text-align: left;
      }
    }
  }
}
@media (max-width: 375px) {
  .centricProcess-section {
    padding: 0 5px;
    .mgb-30 {
      margin-bottom: 10px;
    }
  }
}
