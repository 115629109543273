@import "../../styles/Variables.scss";

.history-section {
  .main-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: 1px;
    margin-top: 0;
  }
  .content {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
  }
  .card-section {
    padding: 15px;
    text-align: initial;

    p {
      margin-bottom: 0;
    }
  }

  .title-content {
    margin: 0;
    margin-bottom: 5px;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
  }

  .content-text {
    font-style: normal;
    color: $titleColor;
    font-size: 30px;
    font-weight: 600;
  }

  .timeline-section {
    margin-top: 30px;

    .timeline {
      max-width: 100%;
      list-style: none;
      padding: 20px 0 20px;
      position: relative;
    }

    .timeline:before {
      top: 60px;
      bottom: 105px;
      position: absolute;
      content: " ";
      width: 3px;
      background-color: #eeeeee;
      left: 50%;
      margin-left: -1.5px;
    }

    .arrowhead {
      width: 0;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -15px;
      display: none;

      border-top: 0 solid transparent;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid #eeeeee;
    }

    .timeline > li {
      min-height: 200px;
      width: 50%;
      padding-left: 42px;
      padding-right: 42px;
      margin-bottom: 30px;
      position: relative;
      float: left;
      clear: left;
      &:nth-last-child(1) {
        min-height: 0px;
      }
    }

    /*Panel*/
    .timeline > li > .timeline-panel {
      width: 100%;
      float: left;
      border: 1px solid transparent;
      border-radius: 5px;
      /*padding: 20px;*/
      position: relative;
      // -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
      // box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    }

    .timeline > li > .timeline-panel:before {
      position: absolute;
      top: 26px;
      right: -15px;
      display: inline-block;
      border-top: 15px solid transparent;
      // border-left: 15px solid #ccc;
      // border-right: 0 solid #ccc;
      border-bottom: 15px solid transparent;
      content: " ";
    }

    .timeline > li > .timeline-panel:after {
      position: absolute;
      top: 27px;
      right: -14px;
      display: inline-block;
      border-top: 14px solid transparent;
      border-left: 14px solid #f5f0e6;
      border-right: 0 solid #f5f0e6;

      border-bottom: 14px solid transparent;
      content: " ";
    }

    .timeline-panel img {
      width: 100%;
      padding: 10px 10px 0 10px;
    }

    /*Inverted panel*/
    .timeline > li.timeline-inverted > .timeline-panel {
      float: right;
    }

    .timeline > li.timeline-inverted > .timeline-panel:before {
      border-left-width: 0;
      border-right-width: 15px;
      left: -15px;
      right: auto;
    }

    .timeline > li.timeline-inverted > .timeline-panel:after {
      border-left-width: 0;
      border-right-width: 14px;
      left: -14px;
      right: auto;
    }

    .timeline > li.timeline-inverted {
      float: right;
      clear: right;
      margin-top: 0px;
      margin-bottom: 30px;
    }

    .timeline > li:nth-child(3) {
      margin-top: 100px;
    }

    .timeline > li > .timeline-badge {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 1.2em;
      text-align: center;
      position: absolute;
      top: 16px;
      right: 5px;
      z-index: 100;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
      cursor: default;
    }

    .timeline > li.timeline-inverted > .timeline-badge {
      left: 5px;
    }

    .timeline > li > .timeline-badge:before {
      position: absolute;
      top: 58%;
      right: -11px;
      content: " ";
      display: block;
      width: 12px;
      height: 12px;
      margin-top: -10px;
      // background: #fff;
      border-radius: 10px;
      z-index: 10;
    }

    .timeline > li.timeline-inverted > .timeline-badge:before {
      left: -11px;
    }

    /*Content*/
    .timeline-title {
      margin-top: 0;
      padding: 12px;
      color: inherit;
      // box-shadow: 1px 1px 4px rgb(153 0 0 0. 25%);
    }

    .timeline-body > p,
    .timeline-body > ul {
      padding: 0 20px 20px 20px;
      margin-bottom: 0;
    }

    .timeline-body > p + p {
      margin-top: 5px;
    }

    .timeline-footer {
      background-color: #f4f4f4;
      padding: 10px;
      text-align: center;
    }

    .timeline-footer > a {
      padding: 20px;
      cursor: pointer;
      text-decoration: none;
    }

    .timeline-vert {
      .timeline-badge:before {
        background-color: $darkRed;
      }

      .timeline > li > .timeline-panel:after {
        background-color: $primaryColor;
      }

      .bg-blue {
        background-color: $white;

        .title-content {
          color: $darkRed;
        }
      }
    }

    .timeline-inverted {
      .timeline-badge:before {
        background-color: $darkRed;
      }

      .bg-pink {
        background-color: $white;

        .title-content {
          color: $darkRed;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .history-section {
    .timeline-section {
      margin-top: 0;
      .card-section {
        margin: 15px 0;
      }
      .timeline {
        max-width: 100%;
      }

      .timeline > li.timeline-vert {
        .timeline-badge {
          left: 8%;
        }
      }

      .timeline > li {
        padding-left: 60px;
        min-height: unset;
      }

      .timeline > li.timeline-vert > .timeline-badge::before {
        left: -16px;
      }

      .timeline > li.timeline-inverted > .timeline-badge::before {
        left: 18px;
      }
    }

    .timeline::before {
      top: 60px;
      left: 25px !important;
      height: 85%;
    }

    .timeline > li {
      width: 100% !important;
      margin-bottom: 0 !important;
    }

    .timeline > li.timeline-vert > .timeline-panel:after {
      border-right: 0 solid $historybgpink;
      transform: rotate(180deg);
      left: -14px !important;
      position: absolute;
      right: unset;
    }

    .timeline > li:nth-child(3) {
      margin-top: 0 !important;
    }
  }
}

@media (max-width: 375px) {
  .history-section {
    .timeline-section {
      .timeline > li.timeline-vert > .timeline-badge::before {
        left: -10px;
      }
    }

    .timeline::before {
      height: 84%;
    }
  }
}

@media (max-width: 320px) {
  .history-section {
    .timeline-section {
      .timeline > li.timeline-vert > .timeline-badge::before {
        left: -6px;
      }
    }

    .timeline::before {
      height: 85%;
    }
  }
}
