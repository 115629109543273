@import "../../styles/Variables.scss";
.whyaxios-section {
  .layer-bg-orange:hover {
    background-color: $lig-orange;
  }
  .layer-bg-purple:hover {
    background-color: $purplee;
  }
  .layer-bg-skyblue:hover {
    background-color: $bluee;
  }
  .layer-bg-maroon:hover {
    background-color: $maroon-lig;
  }
  .mid-card-section {
    padding: 20px 30px;
    // margin-right: 30px;
    min-height: 408px;
    margin-bottom: 40px;
      .outer-img-card {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        .img-card {
          padding: 10px;
          width: 60px;
          border-radius: 20px;
          height: 60px;

          img {
            height: 40px;
            width: 40px;
          }
      }

      .sub-title {
        margin-bottom: 10px;
      }

      .content {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 480px) {
  .whyaxios-section {
    .mid-card-section {
      margin: 0 15px;
      min-height: unset;
    }
    .content{
      text-align: center;
    }
  }
}
