.custom-service-quality {
  .main-title {
    font-size: 70px;
    line-height: 88px;
  }
  .sub-title {
    font-size: 50px;
  }
  .img-contener {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .front-img {
    object-fit: contain;
    width: 100%;
  }
}
.page-gap {
  margin-top: 40px;
}

@media (max-width: 992px) {
  .custom-service-quality {
    .front-img {
      width: 50%;
    }
  }
}

@media (max-width: 768px) {
  .custom-service-quality {
    .sub-title {
      font-size: 30px;
    }
    .main-title {
      font-size: 40px;
      line-height: 33px;
      margin-bottom: 19px;
    }
  }
}
@media (max-width: 480px) {
  .custom-service-quality {
    padding: 0 10px;
  }
}
@media (max-width: 375px) {
  .custom-service-quality {
    padding: 0 5px;
  }
}
