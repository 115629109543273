@import "../../styles/Variables.scss";

.slider-section {
  .d-flex {
    width: auto !important;
  }
  .slick-prev,
  .slick-next {
    border: 2px solid $bgmaroon;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    padding: 1px 2px 5px 3px;
  }
  .slick-prev {
    transform: rotate(180deg);
    // margin-top: -15px;
  }

  .slick-prev:before {
    content: "\2794";
    color: $bgmaroon;
  }

  .slick-next:before,
  [dir="rtl"] .slick-prev:before {
    content: "\2794";

    color: $bgmaroon;
  }

  .webapp-img {
    height: 100%;
    padding: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 25px;
    }
  }
  .col-md-6{
    margin-bottom: 0;
  }
  .webapp-content {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      object-fit: contain;
    }
    .main-title {
      font-weight: 500;
    }

    .main-btn {
      width: 140px;
      margin-top: 0;
    }
  }
}

@media (max-width: 768px) {
  .slider-section {
    .slider {
      height: auto;
    }
    .slick-prev {
      top: 30%;
    }

    .slick-next {
      top: 34%;
    }
  }
}
@media (max-width: 480px) {
  .slider-section {
    .webapp-content {
      padding: 30px;
    }
    .webapp-img {
      padding: 30px;
    }
    .slick-prev {
      top: 23%;
      left: -4px;
    }
    .slick-next {
      top: 25%;
      right: -5px;
    }
  }
}

@media (max-width: 375px) {
  .slider-section {
    .slick-prev {
      top: 20%;
    }
    .slick-next {
      top: 22%;
    }
  }
}

@media (max-width: 360px) {
  .slider-section {
    .slick-prev {
      top: 17%;
    }
    .slick-next {
      top: 19%;
    }
  }
}

@media (max-width: 320px) {
  .slider-section {
    .slick-prev {
      top: 15%;
    }
    .slick-next {
      top: 17%;
    }
  }
}
