@import "../../styles/Variables.scss";
.sub-banner-section {
  background-color: $mainColor;
  padding: 40px 60px;
  min-height: 300px;

  h2,
  p {
    color: white;
  }
}
@media (max-width: 992px) {
  .sub-banner-section {
    .sub-banner-content {
      .offset-md-3 {
        width: 80%;
        margin: auto;
      }
    }
  }
}
@media (max-width: 768px) {
  .sub-banner-section {
    .sub-banner-content {
      .offset-md-3 {
        width: 90%;
        margin: auto;
      }
    }
  }
}
@media (max-width: 480px) {
  .sub-banner-section {
    padding: 40px 15px !important;
  }
}
@media (max-width: 365px) {
  .sub-banner-section {
    padding: 30px 10px !important;
  }
}
