@import "../../styles/Variables.scss";

.step-section {
  .back-btn {
    color: #ae1c13;
  }

  .css-a1sg1c-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    color: #7b0405;
  }

  .main-btn {
    margin: 30px;
  }

  .bi-arrow-left {
    margin-right: 8px;
  }

  .bi-arrow-right {
    margin-left: 8px;
  }

  .css-sxaaqm-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    display: none;
  }
  .css-117w1su-MuiStepIcon-text {
    visibility: hidden;
  }
  .white-font {
    color: white !important;
  }
}

@media (max-width: 992px) {
  .step-section {
    .stepper-content {
      width: auto;
    }
  }
}
@media (max-width: 600px) {
  .step-section {
    .css-ascpo7-MuiStepLabel-root {
      flex-direction: column;

      .MuiStepLabel-iconContainer {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 480px) {
  .step-section {
    .stepper-content {
      label:before {
        top: 15px;
        right: 15px;
      }
    }

    .css-uboc2l {
      button {
        margin-bottom: 30px;
        margin: 0;
      }
    }
  }
}

@media (max-width: 375px) {
  .step-section {
    .css-qivjh0-MuiStepLabel-label {
      font-size: 13px;
    }

    .main-title {
      font-size: 18px;
    }

    .card-section {
      padding: 18px;
      .checkbox-content {
        font-size: 18px;
      }
    }
    .css-uboc2l {
      button {
        margin: 0;
      }
    }
  }
}

@media (max-width: 360px) {
  .stepper-section {
    .step-section {
      .css-qivjh0-MuiStepLabel-label {
        font-size: 12px;
      }
      .css-uboc2l {
        flex-direction: column;
      }

      .css-sxaaqm-MuiButtonBase-root-MuiButton-root {
        margin: 10px;
      }

      .main-btn {
        margin: 10px;
      }
    }
  }
}

@media (max-width: 320px) {
  .stepper-section {
    .step-section {
      .main-title {
        font-size: 14px;
        line-height: 20px;
      }

      .content {
        font-size: 12px;
      }
      .css-qivjh0-MuiStepLabel-label {
        font-size: 10px;
      }
    }
  }
}
