@import "../../styles/Variables.scss";

.step-one {
  ul {
    list-style-type: none;
  }

  li {
    display: inline-block;
  }

  input[type="checkbox"][id^="myCheckbox"] {
    display: none;
  }

  label {
    // border: 1px solid transparent;
    padding: 10px;
    display: block;
    position: relative;
    margin: 0px;
    cursor: pointer;
  }

  .images {
    width: 35px;
    height: 35px;
  }

  .checkbox-content {
    width: calc(100% - 35px);
  }

  label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: 2px;
    right: 2px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  .head-section {
    width: 100%;
    .main-title {
      width: 70%;
      margin: auto;
    }
  }

  label img {
    height: 100px;
    width: 100px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }

  .checked {
    border-color: #ddd;
  }

  .checked {
    &::before {
      content: "\2713";
      background-color: $darkRed;
      transform: scale(1);
    }
  }

  .checked img {
    transform: scale(0.9);
    /* box-shadow: 0 0 5px #333; */
    z-index: -1;
  }

  .card-section {
    display: flex;
    height: 95px;
    align-items: center;
  }

  .stepper-content {
    margin: auto;
    width: 70%;
  }
}
@media (max-width: 768px) {
  .step-one {
    .head-section {
      .main-title {
        width: 80%;
      }
    }
  }
}
@media (max-width: 480px) {
  .step-one {
    .col-6{
      margin-bottom: 0;
    }
    .head-section {
      .main-title {
        width: 100%;
      }
    }
    .card-section {
      padding: 15px 15px;
      height: unset;
      display: block;
      .checkbox-content {
        width: 100%;
      }
      .images {
        margin: 10px 0;
      }
    }
  }
}
