.devprocess-section {
  .mgr-3 {
    margin-right: 40px;
  }
  .row-reverse {
    flex-direction: row-reverse;
  }
  .mgl-3 {
    margin-left: 50px;
  }

  .devprocess-contain {
    position: relative;
    margin-bottom: 20px;
    margin-top: 60px;
    img {
      position: absolute;
      width: 100%;
      display: block;
      top: 133px;
      right: 14px;
      height: 82%;
    }
    .sm-field {
      width: 85%;
    }
    .mgl-2 {
      margin-left: 65px;
    }
    .mgl-1 {
      margin-left: 45px;
    }
    fieldset {
      border: 1px dashed rgba(153, 0, 0, 0.5);
      // width: 350px;
      position: relative;
      border-radius: 10px;
      box-sizing: border-box;
      margin-bottom: 40px;
      margin: 20px;
    }
    legend {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0px;
      width: 30px;
      background-color: #ffffff;
      position: absolute;
      top: -10px;
      left: 10px;
    }
    .devprocess-details {
      padding: 20px;
      text-align: initial;
      position: relative;
      min-height: 157px;

      .main-title {
        font-size: 14px;
        text-transform: uppercase;
        line-height: 30px;
        font-weight: 600;
        margin-bottom: 0;
      }

      .content {
        line-height: 24px;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
  @media (max-width: 992px) {
    .devprocess-contain {
      margin-top: 20px;
      img {
        top: 105px;
        right: 25px;
        height: unset;
      }
      fieldset {
        margin: 15px 10px;
      }
      .sm-field {
        width: 100%;
      }
      .mgl-2 {
        margin-left: unset;
      }
      .mgl-1 {
        margin-left: unset;
      }
      .content {
        margin-bottom: 0;
      }
      .main-title {
        line-height: 24px !important;
      }
      .devprocess-details {
        padding: 15px;
        min-height: 117px;
      }
    }

    .mgr-3 {
      margin-right: 10px;
    }
    .mgl-3 {
      margin-left: 10px;
    }
  }
}

@media (max-width: 768px) {
  .row-reverse {
    flex-direction: unset !important;
  }
  .devprocess-section {
    .devprocess-contain {
      fieldset {
        margin: 10px 0;
      }
      .devprocess-details {
        min-height: 150px;
      }

      .dev-img {
        display: none;
      }
    }
    .mgr-3 {
      margin-right: 0px;
    }
    .mgl-3 {
      margin-left: 0px;
    }
  }
}

@media (max-width: 480px) {
  .devprocess-section {
    padding: 0 10px;
    .mgl-3 {
      margin-left: 0;
    }

    .mgr-3 {
      margin-right: 0;
    }
    .devprocess-contain {
      .devprocess-details {
        min-height: 0;

        .content {
          margin-bottom: 0;
        }
      }
      .dev-img {
        display: none;
      }
    }
    fieldset {
      width: 100%;
    }
  }
  .sm-field {
    width: 100% !important;
  }
  .mgl-2 {
    margin-left: unset !important;
  }
  .mgl-1 {
    margin-left: unset !important;
  }
}
@media (max-width: 375px) {
  .devprocess-section {
    padding: 0 5px;
  }
}
