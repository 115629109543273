@import "../../styles/Variables.scss";

.footer-section {
  .error {
    font-size: 13px;
    color: $errorColor;
    margin-bottom: 14px;
    text-align: left;
    padding: 10px;
  }
  .container {
    
      .d-flex {
        margin-bottom: 43px;
        justify-content: unset;
      }
      .mb-10 {
        margin-bottom: 24px;
      }
      .list-space {
        li {
          list-style: none;
          margin-bottom: 12px;
          a {
            text-decoration: none;
          }
        }
      }

      .logo {
        position: absolute;
        z-index: 999;
        top: 50px;
        right: 19px;
        border: none;
        background-color: transparent;
        float: right;
        .img-logo {
          width: 20px;
        }
      }
      .hold {
        .input-field {
          background-color: #d5d6d8;
          padding: 12px;
          position: relative;
        }
      }
      .mg-top {
        margin-top: 10px;
      }
      .mb-10 {
        margin-bottom: 10px;
      }

      .logo-img {
        height: 40px;
        margin-right: 20px;
      }
      .logo-path {
        width: 15px !important;
      }

      .mg-auto {
        margin: auto 0;
      }
      .img-card {
        background: #eaeaeb;
        padding: 9px;
        border-radius: 50px;
        margin-right: 20px;
        img {
          display: flex;
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .hg {
      color: rgba(44, 49, 58, 1);
    }
  }
 


@media (max-width: 992px) {
  .footer-section {
    .container {
      
        .d-flex {
          margin-bottom: 0;
          .content {
            font-size: 16px;
            line-height: 22px;
          }
        }
        .logo-img {
          margin-right: 10px;
        }
      }
    
  }
}

@media (max-width: 375px) {
  .footer-section {
    .container {
      
        .d-flex {
          margin-bottom: 40px;

          .content {
            font-size: 14px;
            line-height: 20px;
          }
          .img-card {
            padding: 8px;
            margin: 17px 13px 0 0;
          }
        }

        .content {
          font-size: 14px;
          line-height: 20px;
        }

        .mb-10 {
          margin-bottom: 22px;
        }
        .list-space {
          li {
            margin-bottom: 10px;
          }
        }
        .hold {
          .input-field {
            padding: 7px;
            font-size: 14px;
          }
        }
        .logo {
          top: 57px !important;
          right: 35px !important;
          width: 18px;
        }
      }
    
  }
}
@media (max-width: 480px) {
  .footer-section {
    .d-flex {
      margin-bottom: 20px !important;
      .content {
        font-size: 16px;
        line-height: 20px;
      }
    }
    .hg {
      margin-bottom: 14px;
    }
  }
}


